import { Component, OnInit, Output, EventEmitter, inject, DestroyRef } from '@angular/core';
import _ from 'lodash';
import { FilterOption, FilterOptionType } from '@shared/models/filter';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { LayersService } from '@core/services/layers.service';
import { removeDiacritics } from '@shared/utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'fields-search-bar',
    templateUrl: './fields-search-bar.component.html',
    styleUrls: ['./fields-search-bar.component.less'],
})
export class FieldsSearchBarComponent implements OnInit {
    prefixFilterOptions: Array<{ label: string; value: FilterOption }> = [];
    cropTypeFilterOptions: Array<{ label: string; value: FilterOption }> = [];
    fieldNameFilterOptions: Array<{ label: string; value: FilterOption }> = [];

    currentFilters: any;
    fields: Field[] = [];

    destroyRef = inject(DestroyRef);

    @Output() filtered = new EventEmitter<Array<number>>();

    constructor(private layersService: LayersService) {}

    ngOnInit() {
        this.layersService.Fields.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((fields) => {
            if (!_.isEmpty(fields)) {
                this.fields = fields;

                const cropTypes: CropType[] = this.layersService.extractCropTypes(fields);

                // Clear previous options
                this.prefixFilterOptions = [];
                this.cropTypeFilterOptions = [];
                this.fieldNameFilterOptions = [];

                // Populate prefix filters
                const prefixes: Set<string> = new Set<string>([]);

                for (const field of fields) {
                    const index: number = field.name.indexOf(' ');

                    if (index !== -1 && field.name.startsWith('BF')) {
                        const prefix: string = field.name.slice(0, index);
                        prefixes.add(_.trim(prefix));
                    }
                }

                prefixes.forEach((prefix) => {
                    this.prefixFilterOptions.push({
                        label: prefix,
                        value: {
                            type: FilterOptionType.Prefix,
                            value: prefix,
                        },
                    });
                });

                // Populate crop type filters
                _.forEach(cropTypes, (cropType) =>
                    this.cropTypeFilterOptions.push({
                        label: cropType.name,
                        value: {
                            type: FilterOptionType.CropType,
                            value: cropType.name,
                        },
                    }),
                );

                // Populate field filters
                _.forEach(this.fields, (field) =>
                    this.fieldNameFilterOptions.push({
                        label: field.name || field.id.toString(),
                        value: {
                            type: FilterOptionType.FieldName,
                            value: field.name || field.id,
                        },
                    }),
                );
            }
        });
    }

    /**
     * Visually removes the current filters (if any) in the UI element.
     */
    public resetCurrentFilters(): void {
        this.currentFilters = [];
    }

    /**
     * Filter fields and emit field ids
     */
    onFilterChanged(filterOptions: FilterOption[]): void {
        if (_.isEmpty(filterOptions)) {
            this.filtered.emit(null);
            return;
        }

        this.filtered.emit(this.layersService.filterCurrentFields(filterOptions));
    }

    /**
     * Compares if a string contains another one, ignoring diacritics
     */
    compare = (value, option) =>
        removeDiacritics(option.nzValue.value).includes(removeDiacritics(value));
}
