<nz-select
    [nzAllowClear]="true"
    style="width: 100%"
    nzMode="multiple"
    nzPlaceHolder="Filtrează terenurile"
    [(ngModel)]="currentFilters"
    (ngModelChange)="onFilterChanged($event)"
    [nzFilterOption]="compare"
    [nzNotFoundContent]="'Nu s-a găsit niciun rezultat'"
>
    <nz-option-group [nzLabel]="'Tipuri culturi (' + cropTypeFilterOptions.length + ')'">
        <nz-option
            *ngFor="let option of cropTypeFilterOptions"
            [nzLabel]="option.label"
            [nzValue]="option.value"
            nzCustomContent
        >
            <div class="Option">{{ option.label }}</div>
        </nz-option>
    </nz-option-group>

    <nz-option-group
        [nzLabel]="'Blocuri fizice (' + prefixFilterOptions.length + ')'"
        *ngIf="prefixFilterOptions.length"
    >
        <nz-option
            *ngFor="let option of prefixFilterOptions"
            [nzLabel]="option.label"
            [nzValue]="option.value"
            nzCustomContent
        >
            <div class="Option">{{ option.label }}</div>
        </nz-option>
    </nz-option-group>

    <nz-option-group [nzLabel]="'Terenuri (' + fieldNameFilterOptions.length + ')'">
        <nz-option
            *ngFor="let option of fieldNameFilterOptions"
            [nzLabel]="option.label"
            [nzValue]="option.value"
            nzCustomContent
        >
            <div class="Option">{{ option.label }}</div>
        </nz-option>
    </nz-option-group>
</nz-select>
