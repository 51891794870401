import { Component, OnInit, Input } from '@angular/core';
import { PinsService } from '@modules/pins/services/pins.service';
import { take, delay, catchError, tap } from 'rxjs/operators';
import { Pin } from '@shared/models/pins/pin';
import _ from 'lodash';
import { format } from 'date-fns';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { removeDiacritics } from '@shared/utils/utils';
import { environment } from '@environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';
import { PermissionsService } from '@core/services/permissions.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LayersService } from '@core/services/layers.service';
import { saveAs } from 'file-saver';
import Field from '@shared/models/field';

@Component({
    selector: 'pins-list',
    templateUrl: './pins-list.component.html',
    styleUrls: ['./pins-list.component.less'],
    animations: [
        trigger('fadeOut', [
            transition(':leave', [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]),
        ]),
        trigger('fadeIn', [
            transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
        ]),
    ],
})
export class PinsListComponent implements OnInit {
    @Input()
    fieldId: any;

    /**
     * Models
     */
    pins: Pin[] = [];
    filteredPins: Pin[] = [];
    selectedPin: Pin;
    searchValue: string | null;
    currentModalImage: any;

    /**
     * The pin which is being edited in the pinEditingModal
     */
    editingPin: Pin;

    isLoading = true;
    isImageModalVisible = false;
    isPinEditingModalVisible = false;
    isSavingPin = false;

    isLoadingImagesArchive = false;

    /**
     * Style objects
     */
    pinMapStyle = {
        height: '160px',
        width: '100%',
        background: 'white',
        cursor: 'pointer',
    };

    currentPinImages = [];

    /**
     * Permissions
     */
    permissions = [];

    private isLoggingEnabled = !environment.production;

    constructor(
        private message: NzMessageService,
        private pinsService: PinsService,
        private modal: NzModalService,
        private permissionsService: PermissionsService,
        private layersService: LayersService,
    ) {}

    ngOnInit() {
        // Obtain permissions
        this.permissions = this.permissionsService.Permissions;

        this.fieldId = Number.parseInt(this.fieldId);

        this.pinsService
            .getPinsOfField(this.fieldId)
            .pipe(take(1))
            .subscribe((pins: Pin[]) => {
                this.pins = _(pins)
                    .map((pin, index) => ({
                        ...pin,
                        moment: moment.utc(pin.created_at).toISOString(),
                    }))
                    .orderBy(['moment'], ['desc'])
                    .value();

                this.filteredPins = this.pins;

                if (!_.isEmpty(this.pins)) {
                    this.selectPin(this.pins[0].id);
                }

                this.isLoading = false;
            });
    }

    selectPin = (pinId: number): void => {
        this.filteredPins.forEach((pin) => (pin.isSelected = false));
        this.pins.forEach((pin) => (pin.isSelected = false));

        this.selectedPin = this.filteredPins.find((pin) => pin.id === pinId);

        this.currentPinImages = this.selectedPin.media.map((src, index) => ({
            index,
            src,
        }));

        this.selectedPin.isSelected = true;

        if (this.isLoggingEnabled) console.log('[PinsList] selectedPin', this.selectedPin);
    };

    displayMessage = (type: string, message: string): any => this.message.create(type, message);

    onTextChanged = (value: string): void => {
        if (!value) {
            this.filteredPins = this.pins;
        } else {
            this.filteredPins = this.pins.filter((pin) => {
                if (!pin.text) return false;
                return removeDiacritics(pin.text).includes(removeDiacritics(value));
            });
        }
    };

    downloadPinImages = (): void => {
        if (!this.selectedPin) {
            return;
        }

        const field: Field = this.layersService.getField(this.fieldId);

        if (!field) {
            return;
        }

        this.isLoadingImagesArchive = true;
        const pinId = this.selectedPin.id;

        this.pinsService
            .getPinImagesArchive(pinId)
            .pipe(take(1))
            .subscribe((data: Blob) => {
                const blob = new Blob([data], {
                    type: 'application/zip',
                });

                saveAs(
                    blob,
                    `${field.name}_${pinId}_${this.selectedPin.created_at.slice(0, 10)}.zip`,
                );

                this.isLoadingImagesArchive = false;
            });
    };

    displayDeleteConfirmation = (): void => {
        const victim: Pin = this.selectedPin;

        this.modal.confirm({
            nzTitle: 'Ești sigur că vrei să ștergi următoarea notiță?',
            nzContent: `<div">${victim.text || ''} (${format(
                new Date(victim.moment),
                'HH:mm, DD-MM-YYYY',
            )})</div>`,
            nzOkText: 'Șterge',
            nzCancelText: 'Anulează',
            nzOnOk: () =>
                this.pinsService
                    .deletePin(victim.id)
                    .pipe(
                        take(1),
                        catchError(this.errorHandler),
                        delay(100),
                        tap(
                            () => {
                                this.filteredPins = this.filteredPins.filter(
                                    (pin) => pin.id !== victim.id,
                                );
                                this.pins = this.pins.filter((pin) => pin.id !== victim.id);

                                if (!_.isEmpty(this.pins)) {
                                    this.selectPin(this.pins[0].id);
                                } else {
                                    this.selectedPin = null;
                                }

                                this.displayMessage('success', 'Notița a fost ștearsă');
                            },
                            (error) => {
                                this.displayMessage('error', 'A apărut o eroare la ștergere');
                                console.error(error);
                            },
                        ),
                    )
                    .toPromise(),
        });
    };

    openPinEditingModal = (): void => {
        this.editingPin = _.cloneDeep(this.selectedPin);
        this.isPinEditingModalVisible = true;
    };

    closePinEditingModal = (): void => {
        this.isPinEditingModalVisible = false;
    };

    /**
     * Updates a pin's text property
     */
    editPin = (): void => {
        const text: string = this.editingPin.text;

        if (_.size(text) > 500) {
            this.displayMessage('warning', 'Introduceți maxim 500 de caractere');
            return;
        }

        this.isSavingPin = true;

        this.pinsService
            .updatePin(this.selectedPin.id, { text })
            .pipe(take(1), catchError(this.errorHandler), delay(100))
            .subscribe(
                (updatedPin: Pin) => {
                    this.selectedPin.text = updatedPin.text;

                    this.isSavingPin = false;
                    this.isPinEditingModalVisible = false;
                    this.displayMessage('success', 'Notița a fost modificată');
                },
                (error) => {
                    this.isSavingPin = false;
                    this.isPinEditingModalVisible = false;
                    this.displayMessage('error', 'A apărut o eroare la salvare');
                    console.error(error);
                },
            );
    };

    openImageModal = (image: any): void => {
        this.currentModalImage = image;
        this.isImageModalVisible = true;
    };

    closeImageModal = (): void => {
        this.currentModalImage = null;
        this.isImageModalVisible = false;
    };

    onClickedOutside(event: MouseEvent): void {
        if ((event.target as HTMLElement).classList.contains('Image-Modal-Container'))
            this.closeImageModal();
    }

    /**
     * View the image to the left of the current one
     */
    scrollModalLeft(): void {
        this.currentModalImage = this.currentPinImages[this.currentModalImage.index - 1];
    }

    /**
     * View the image to the right of the current one
     */
    scrollModalRight(): void {
        this.currentModalImage = this.currentPinImages[this.currentModalImage.index + 1];
    }

    private errorHandler = (errorResponse: Response): Observable<never> => {
        console.error('[ClientsList] Error Handler');
        console.error(errorResponse);
        this.displayMessage('error', 'A apărut o eroare');

        return throwError(errorResponse || 'API Error');
    };
}
