<div class="Wrapper">
    <div *ngIf="!isLoading; else loading">
        <div class="Card No-Padding-Card">
            <div class="Pins-Wrapper" *ngIf="pins.length > 0 && !isLoading">
                <div class="Pins-List">
                    <div class="List-Toolbox">
                        <div class="Header">
                            Notițe
                            <div class="Pins-Count">({{ pins.length }})</div>
                        </div>

                        <div *ngIf="pins.length > 0" class="List-Search-Bar">
                            <nz-input-group
                                [nzSuffix]="suffixTemplate"
                                [nzPrefix]="prefixTemplate"
                                nzSize="small"
                            >
                                <input
                                    type="text"
                                    nz-input
                                    [(ngModel)]="searchValue"
                                    (ngModelChange)="onTextChanged($event)"
                                    placeholder="Caută"
                                />
                            </nz-input-group>

                            <ng-template #prefixTemplate
                                ><i nz-icon nzType="search"></i
                            ></ng-template>

                            <ng-template #suffixTemplate
                                ><i
                                    nz-icon
                                    nz-tooltip
                                    class="ant-input-clear-icon"
                                    nzTheme="fill"
                                    nzType="close-circle"
                                    *ngIf="searchValue"
                                    (click)="searchValue = null; onTextChanged(null)"
                                ></i
                            ></ng-template>
                        </div>

                        <div class="Toolbox-Buttons-Container">
                            <button
                                [disabled]="!permissions.includes('core.change_note')"
                                *ngIf="selectedPin"
                                class="Edit-Pin-Button"
                                (click)="openPinEditingModal()"
                                nz-button
                                nzType="default"
                                nzShape="circle"
                                nzSize="small"
                            >
                                <i nz-icon nzType="edit"></i>
                            </button>

                            <button
                                [disabled]="!permissions.includes('core.delete_note')"
                                *ngIf="selectedPin"
                                (click)="displayDeleteConfirmation()"
                                nz-button
                                nzType="danger"
                                nzShape="circle"
                                nzSize="small"
                            >
                                <i nz-icon nzType="delete"></i>
                            </button>
                        </div>
                    </div>

                    <div class="Pins-List-Wrapper">
                        <div
                            class="Pin-Item Cursor-Pointer"
                            *ngFor="let pin of filteredPins; index as i"
                            (click)="selectPin(pin.id)"
                            [class.Selected]="pin.isSelected"
                        >
                            <div class="Flex-Row">
                                <i class="Pin-Icon" nz-icon nzType="pushpin" nzTheme="outline"></i>
                                <div class="Text" [class.Selected-Text]="pin.isSelected">
                                    {{ pin?.text || 'Fără text' }}
                                </div>
                            </div>

                            <div class="Flex-Row">
                                <i
                                    class="Paper-Clip-Icon"
                                    [class.Visible]="pin.media.length > 0"
                                    nz-icon
                                    nzType="paper-clip"
                                    nzTheme="outline"
                                ></i>
                                <div class="Note-Id">#{{ pin?.id }}</div>
                                <div class="Date-Wrapper Flex-Row text-gray-500">
                                    <i nz-icon nzType="calendar" nzTheme="outline"></i>
                                    {{ pin?.moment | date: 'dd MMM y' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="Pin-Content"
                    [class.Full-Width]="pins.length == 0 && !isLoading"
                    [class.Reduced-Padding]="pins.length == 0 && !isLoading"
                >
                    <ng-container *ngIf="selectedPin">
                        <div class="Task-Id">#{{ selectedPin?.id }}</div>

                        <div class="Task-Text">{{ selectedPin?.text || 'Fără text' }}</div>

                        <div class="Flex-Row Content-Date-Wrapper">
                            <i class="Item-Icon" nz-icon nzType="calendar" nzTheme="outline"></i>
                            <div class="Item-Data">
                                <div class="Item-Title">Data creării</div>
                                <div class="Item-Value">
                                    {{ selectedPin?.moment | date: 'mediumDate' }}
                                </div>
                            </div>
                        </div>

                        <div class="Full-Width">
                            <div class="Pins-Map-Wrapper">
                                <map-mini-map-view
                                    fieldId="{{ selectedPin.field }}"
                                    padding="large"
                                    [containerStyle]="pinMapStyle"
                                    [pinCoordinates]="selectedPin.coordinates"
                                ></map-mini-map-view>
                            </div>
                        </div>

                        <div class="Section-Title-Wrapper Section-Margin Flex-Row">
                            <div class="Flex-Row">
                                <i
                                    class="Camera-Icon"
                                    nz-icon
                                    nzType="camera"
                                    nzTheme="outline"
                                ></i>
                                <div class="Title Weight-500">Fotografii</div>
                                <div class="Count Weight-500">({{ currentPinImages.length }})</div>
                            </div>

                            <button
                                *ngIf="currentPinImages?.length"
                                (click)="downloadPinImages()"
                                nz-button
                                nzSize="small"
                                [nzLoading]="isLoadingImagesArchive"
                            >
                                <i nz-icon nzType="download"></i>
                                Descarcă
                            </button>
                        </div>

                        <div class="Media-Container">
                            <div class="Image-Wrapper" *ngFor="let image of currentPinImages">
                                <div class="Image-Overlay" (click)="openImageModal(image)">
                                    <i nz-icon nzType="eye" nzTheme="outline"></i>
                                </div>

                                <img [src]="image.src" />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="pins.length == 0 && !isLoading" class="No-Data-Container">
                <nz-empty [nzNotFoundContent]="noDataTemplate">
                    <ng-template #noDataTemplate>
                        <p>Nu există notițe</p>
                    </ng-template>
                </nz-empty>
            </div>
        </div>
    </div>
</div>

<!-- Image modal -->
<div class="Image-Modal-Container" *ngIf="isImageModalVisible" @fadeOut>
    <div class="Wrapper">
        <div class="Arrows-Container">
            <div
                [class.Invisible]="currentModalImage.index == 0"
                class="Arrow-Wrapper-Large"
                (click)="scrollModalLeft()"
            >
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </div>

            <div
                [class.Invisible]="currentModalImage.index == currentPinImages.length - 1"
                class="Arrow-Wrapper-Large"
                (click)="scrollModalRight()"
            >
                <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
            </div>
        </div>

        <a [href]="currentModalImage.src" [download]="currentModalImage.file_name">
            <img @fadeIn (clickOutside)="onClickedOutside($event)" [src]="currentModalImage.src" />
        </a>
    </div>

    <div class="Close-Icon-Container">
        <i (click)="closeImageModal()" nz-icon nzType="close" theme="outline"></i>
    </div>
</div>

<nz-modal
    *ngIf="editingPin"
    nzWrapClassName="vertical-center-modal"
    [(nzVisible)]="isPinEditingModalVisible"
    nzTitle="Editare notiță"
    (nzOnCancel)="closePinEditingModal()"
    (nzOnOk)="editPin()"
    nzOkText="Salvează"
    nzCancelText="Anulează"
    [nzOkLoading]="isSavingPin"
>
    <textarea rows="6" name="descriere" nz-input [(ngModel)]="editingPin.text"></textarea>
</nz-modal>

<ng-template #loading>
    <div class="Loading-Indicator Flex-Center-All">
        <i nz-icon nzType="loading"></i>
    </div>
</ng-template>
