import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsRoutingModule } from './fields-routing.module';
import { FieldsListComponent } from './components/fields-list/fields-list.component';
import { CoreModule } from '@core/core.module';
import { FieldCardComponent } from './components/field-card/field-card.component';
import { SharedModule } from '@shared/shared.module';
import { MapModule } from '@modules/map/map.module';
import { FieldsSearchBarComponent } from './components/fields-search-bar/fields-search-bar.component';
import { FormsModule } from '@angular/forms';
import { FieldDetailsComponent } from './components/field-details/field-details.component';
import { PinsModule } from '@modules/pins/pins.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    declarations: [
        FieldsListComponent,
        FieldCardComponent,
        FieldsSearchBarComponent,
        FieldDetailsComponent,
    ],
    imports: [
        CommonModule,
        FieldsRoutingModule,
        CoreModule,
        SharedModule,
        MapModule,
        FormsModule,
        PinsModule,
        NzInputModule,
        NzSelectModule,
        NzSpinModule,
        NzAlertModule,
        NzPaginationModule,
        NzToolTipModule,
        NzButtonModule,
        NzIconModule,
    ],
})
export class FieldsModule {}
