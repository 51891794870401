import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PinsRoutingModule } from './pins-routing.module';
import { PinsListComponent } from './components/pins-list/pins-list.component';
import { MapModule } from '@modules/map/map.module';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
    declarations: [PinsListComponent],
    imports: [
        CommonModule,
        PinsRoutingModule,
        MapModule,
        FormsModule,
        NgClickOutsideDirective,
        NzEmptyModule,
        NzButtonModule,
        NzIconModule,
        NzModalModule,
        NzInputModule,
    ],
    exports: [PinsListComponent],
})
export class PinsModule {}
