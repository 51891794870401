<core-header></core-header>

<div class="Content-Container Centered-Content">
    <nz-spin [nzSpinning]="isLoading" [nzIndicator]="indicatorTemplate" [nzDelay]="200">
        <div class="Bar">
            <fields-search-bar (filtered)="onFilterChanged($event)"></fields-search-bar>

            <button
                class="Edit-Fields-Button"
                nz-button
                nzType="primary"
                (click)="editFields()"
                [disabled]="allFields.length == 0 || !canModifyFields"
            >
                <i nz-icon nzType="edit"></i>Editează terenurile
            </button>
        </div>

        <div nz-row nzGutter="32" *ngIf="allFields.length">
            <div nz-col nzSpan="24">
                <div class="Card Bar Stats-Wrapper">
                    <fields-stats [fields]="filteredFields"></fields-stats>
                </div>
            </div>
        </div>

        <div class="Flex-Column">
            <p *ngIf="!currentFields.length">
                <nz-alert nzType="warning" nzMessage="Nu există terenuri"></nz-alert>
            </p>

            <ng-container *ngIf="currentFields.length">
                <div class="Cards-Container">
                    <div
                        *ngFor="let field of currentFields; let i = index"
                        routerLink="{{ field.id }}"
                        class="Field-Card"
                    >
                        <fields-card
                            id="{{ field?.id }}"
                            name="{{ field?.name }}"
                            cropTypeId="{{ field?.apia_crop_type }}"
                            area="{{ field?.declared_area || field?.computed_area }}"
                            coordinates="{{ field?.geometry }}"
                            [isAreaComputed]="!field?.declared_area"
                        ></fields-card>
                    </div>
                </div>

                <div nz-row class="Pagination">
                    <div nz-col>
                        <nz-pagination
                            [(nzPageIndex)]="currentPageIndex"
                            [nzTotal]="fieldsCount"
                            [nzPageSize]="pageSize"
                            (nzPageIndexChange)="changedPageIndex($event)"
                        ></nz-pagination>
                    </div>
                </div>
            </ng-container>
        </div>
    </nz-spin>

    <ng-template #indicatorTemplate><i nz-icon nzType="loading" style="font-size: 24px"></i> </ng-template>
</div>
