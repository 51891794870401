import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LayersService } from '@core/services/layers.service';
import { PermissionsService } from '@core/services/permissions.service';
import Field from '@shared/models/field';
import { sortFieldsByBF } from '@shared/utils/utils';
import _ from 'lodash';
import { FieldsSearchBarComponent } from '../fields-search-bar/fields-search-bar.component';

@Component({
    selector: 'fields-list',
    templateUrl: './fields-list.component.html',
    styleUrls: ['./fields-list.component.less'],
})
export class FieldsListComponent implements OnInit {
    destroyRef = inject(DestroyRef);

    fieldsCount = 0;
    totalArea = 0;
    pageSize = 16;
    currentPageIndex = 1;

    allFields: Field[] = [];
    currentFields: Field[] = [];
    filteredFields: Field[] = [];

    isLoading = false;

    /**
     * Permissions
     */
    canModifyFields = false;

    @ViewChild(FieldsSearchBarComponent, { static: true })
    fieldsSearchBar: FieldsSearchBarComponent;

    constructor(
        private layersService: LayersService,
        private permissionsService: PermissionsService,
        private router: Router,
    ) {}

    ngOnInit() {
        // Obtain permissions
        this.canModifyFields = this.permissionsService.canModifyFields();

        this.layersService.isLoadingFields
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isLoading: boolean) => (this.isLoading = isLoading));

        this.layersService.Fields.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (fields: Field[]) => {
                // Reset FieldsSearchBar filters
                this.fieldsSearchBar.resetCurrentFilters();

                this.allFields = sortFieldsByBF(fields);
                this.initializeFields();
            },
        );
    }

    initializeFields(): void {
        this.fieldsCount = _.size(this.allFields);
        this.totalArea = this.layersService.getSetArea(this.allFields, 1);

        this.onFilterChanged(null); // Start with all fields
        this.changedPageIndex(1); // Start with the first page
    }

    /**
     * Handle filtering of selected fields
     * @param fieldIds Array of fields ids
     */
    onFilterChanged(fieldIds: Array<any>): void {
        if (!fieldIds) {
            this.filteredFields = this.allFields.slice();
        } else {
            this.filteredFields = [];

            for (const field of this.allFields) {
                if (fieldIds.includes(field.id)) {
                    this.filteredFields.push(field);
                }
            }
        }

        this.fieldsCount = _.size(this.filteredFields);

        // Reset to first page when applying filters
        this.currentPageIndex = 1;
        this.changedPageIndex(1);
    }

    /**
     * Changed page index
     */
    changedPageIndex(pageNumber): void {
        this.currentFields = this.filteredFields.slice(
            (pageNumber - 1) * this.pageSize,
            pageNumber * this.pageSize,
        );

        this.scrollToTop();
    }

    editFields(): void {
        this.router.navigateByUrl(`/editare`);
    }

    scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
