import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FieldsListComponent } from './components/fields-list/fields-list.component';
import { FieldDetailsComponent } from './components/field-details/field-details.component';

const routes: Routes = [
    {
        path: ':fieldId/zona/:childId',
        component: FieldDetailsComponent,
    },
    {
        path: ':fieldId',
        component: FieldDetailsComponent,
    },
    {
        path: '',
        component: FieldsListComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FieldsRoutingModule {}
