import { Component, OnInit, Input } from '@angular/core';
import { LayersService } from '@core/services/layers.service';
import CropType from '@shared/models/crop-type';

@Component({
    selector: 'fields-card',
    templateUrl: './field-card.component.html',
    styleUrls: ['./field-card.component.less'],
})
export class FieldCardComponent implements OnInit {
    @Input()
    id: number;

    @Input()
    name: string;

    @Input()
    cropTypeId: string;

    @Input()
    area: number;

    @Input()
    coordinates: string;

    @Input()
    isAreaComputed?: boolean;

    mapSize = '250px';

    mapStyle = {
        width: this.mapSize,
        height: this.mapSize,
    };

    cropType: CropType;

    constructor(private layersService: LayersService) {}

    ngOnInit(): void {
        this.cropType = this.layersService.getCropType(Number.parseInt(this.cropTypeId));
    }
}
