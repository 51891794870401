<div class="Relative Wrapper">
    <div class="Overlay Flex-Column">
        <div class="Flex-Row Top-Row">
            <div class="Weight-500">{{ name || id }}</div>
            <div nz-tooltip [nzTooltipTitle]="isAreaComputed ? 'Suprafața calculată' : null">
                <span *ngIf="isAreaComputed">*</span>
                <span>{{ area | number: '1.0-2' }} ha</span>
            </div>
        </div>

        <div class="Crop-Type Weight-500" [style.color]="cropType?.field_color">
            {{ cropType?.name }}
        </div>
    </div>

    <map-mini-map-view
        [fieldId]="id"
        [containerStyle]="mapStyle"
        enableLoadingScreen="true"
        padding="large"
        hideBingMapsLayer
    ></map-mini-map-view>
</div>
