<core-header>
    <ng-template>
        <div class="Field-Name-Title">{{ field?.name }}</div>
    </ng-template>
</core-header>

<div *ngIf="field" class="Content-Container Extra-Bottom-Padding Centered-Content">
    <div class="Card No-Padding-Card">
        <div class="Field-Info-Container">
            <div class="Full-Width">
                <div class="Field-Info-Wrapper">
                    <div
                        class="Field-Info-Item"
                        [class.Cursor-Pointer]="canUpdateFieldsProperties"
                        (click)="openEditingModal(FieldProperty.NAME, nameContent)"
                    >
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Nume</div>
                            <i
                                [class.Edit-Icon]="canUpdateFieldsProperties"
                                nz-icon
                                nzType="edit"
                                nzTheme="outline"
                            ></i>
                        </div>
                        <div class="Item-Value">{{ field?.name }}</div>
                    </div>

                    <div
                        class="Field-Info-Item"
                        [class.Cursor-Pointer]="canUpdateFieldsProperties"
                        (click)="openEditingModal(FieldProperty.CROP_TYPE, cropTypeContent)"
                    >
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Cultură</div>
                            <i
                                [class.Edit-Icon]="canUpdateFieldsProperties"
                                nz-icon
                                nzType="edit"
                                nzTheme="outline"
                            ></i>
                        </div>

                        <div class="Item-Value" [style.color]="cropType?.field_color">
                            {{ cropType?.name }}
                        </div>
                    </div>

                    <div class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Suprafață măsurabilă</div>
                        </div>
                        <div class="Item-Value">
                            {{ field?.computed_area | number: '1.0-2' }} ha
                        </div>
                    </div>

                    <div class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Suprafață declarată</div>
                        </div>
                        <div *ngIf="!!field?.declared_area" class="Item-Value">
                            {{ field?.declared_area | number: '1.0-2' }} ha
                        </div>
                        <div *ngIf="!field?.declared_area" class="Item-Value">—</div>
                    </div>

                    <div class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Județ</div>
                        </div>
                        <div *ngIf="field?.county" class="Item-Value">{{ field?.county }}</div>
                        <div *ngIf="!field?.county" class="Item-Value">—</div>
                    </div>

                    <div class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Comună</div>
                        </div>
                        <div *ngIf="field.commune" class="Item-Value">{{ field?.commune }}</div>
                        <div *ngIf="!field.commune" class="Item-Value">—</div>
                    </div>

                    <div *ngIf="!isChild" class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">SIRUTA</div>
                        </div>
                        <div *ngIf="field.siruta" class="Item-Value">{{ field?.siruta }}</div>
                        <div *ngIf="!field.siruta" class="Item-Value">—</div>
                    </div>

                    <div *ngIf="isChild" class="Field-Info-Item">
                        <div class="Item-Title-Wrapper">
                            <div class="Item-Title">Teren</div>
                        </div>
                        <div
                            *ngIf="parentField"
                            routerLink="/terenuri/{{ parentField.id }}"
                            class="Item-Value Parent-Field"
                        >
                            {{ parentField?.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="Relative Mini-Map-Wrapper">
                <div *ngIf="!isChild" class="Map-Button-Container">
                    <button
                        routerLink="/harti/{{ field.id }}/vizualizare/Vegetatie"
                        nz-button
                        nzSize="small"
                        nzType="default"
                    >
                        Vizualizează
                    </button>
                </div>

                <map-mini-map-view
                    *ngIf="field"
                    fieldId="{{ field.id }}"
                    padding="large"
                    [containerStyle]="infoMapStyle"
                ></map-mini-map-view>
            </div>
        </div>
    </div>

    <div *ngIf="isChild" class="Card Card-Margin-Top">
        <div
            class="Details-Wrapper"
            [class.Cursor-Pointer]="canUpdateFieldsProperties"
            (click)="openEditingModal(FieldProperty.DETAILS, detailsContent)"
        >
            <div class="Card-Title-Wrapper">
                <div class="Card-Title">Detalii daună</div>
                <i
                    [class.Edit-Icon]="canUpdateFieldsProperties"
                    nz-icon
                    nzType="edit"
                    nzTheme="outline"
                ></i>
            </div>
            <div *ngIf="field.details" class="Card-Value">{{ field?.details }}</div>
            <div *ngIf="!field.details" class="Card-Value">—</div>
        </div>
    </div>

    <pins-list [fieldId]="field.id"></pins-list>

    <div *ngIf="children.length > 0" class="Card Card-Margin-Top">
        <div class="Isolated-Zones-Container">
            <div class="Card-Header">
                Zone izolate
                <div class="Count">({{ children.length }})</div>
            </div>

            <div class="Isolated-Zones-Wrapper">
                <div
                    class="Isolated-Zone-Card Relative"
                    *ngFor="let child of children"
                    routerLink="/terenuri/{{ field.id }}/zona/{{ child.id }}"
                >
                    <div class="Band-Container">
                        <div class="Isolated-Zone-Name">{{ child?.name }}</div>
                    </div>
                    <map-mini-map-view
                        [fieldId]="child.id"
                        padding="large"
                        [containerStyle]="isolatedZoneMapStyle"
                    ></map-mini-map-view>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Editing modal content templates -->
<ng-template #cropTypeContent let-ref="modalRef">
    <div>
        <nz-select
            class="Modal-Item"
            nzShowSearch
            nzPlaceHolder="Alege un tip de cultură"
            nzNotFoundContent="Tipul de cultură nu a fost găsit"
            [(ngModel)]="cropTypeId"
            [nzFilterOption]="compare"
        >
            <nz-option
                *ngFor="let cropType of cropTypes"
                nzLabel="{{ cropType.name }}"
                nzValue="{{ cropType.id }}"
                nzCustomContent
            >
                <div>{{ cropType.name }}</div>
            </nz-option>
        </nz-select>
    </div>
</ng-template>

<ng-template #nameContent let-ref="modalRef">
    <div>
        <input
            class="Modal-Item"
            nz-input
            nzPlaceHolder="Denumire"
            [(ngModel)]="name"
            name="nume"
            maxlength="64"
            required
            autofocus
        />
    </div>
</ng-template>

<ng-template #detailsContent let-ref="modalRef">
    <div>
        <textarea
            class="Modal-Item"
            nz-input
            placeholder="Introdu detaliile daunei"
            [(ngModel)]="details"
            [nzAutosize]="{ minRows: 2, maxRows: 6 }"
            autofocus
        ></textarea>
    </div>
</ng-template>
