import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Client } from '@shared/models/client';
import { Pin } from '@shared/models/pins/pin';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PinsService {
    constructor(private apiService: ApiService, private clientsService: ClientsService) {}

    /**
     * Returns an array of pins for the current client
     */
    getPinsOfClient(): Observable<Pin[]> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.getPinsOfClient(currentClient.id);
    }

    /**
     * Returns an array of pins for the current client, assigned to the given field
     * @param fieldId The id of the field to which the pins are assigned
     */
    getPinsOfField(fieldId: number): Observable<Pin[]> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.getPinsOfField(currentClient.id, fieldId);
    }

    /**
     * Creates a pin for the current client
     * @param pin The pin object
     */
    createPinOfClient(pin: Pin): Observable<Pin> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.createPinOfClient(currentClient.id, pin);
    }

    /**
     * Creates a pin for the current client, assigned to the given field
     * @param fieldId The id of the field to which the pin is assigned
     * @param pin The pin object
     */
    createPinOfField(fieldId: number, pin: Pin): Observable<Pin> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.createPinOfField(currentClient.id, fieldId, pin);
    }

    /**
     * Deletes a pin on the current client
     * @param pinId The id of the pin to be deleted
     */
    deletePin(pinId: number): Observable<any> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.deletePin(currentClient.id, pinId);
    }

    /**
     * Updates a pin on the current client
     * @param pinId The id of the pin to be updated
     * @param data The properties to be updated
     */
    updatePin(pinId: number, data: any): Observable<any> {
        const currentClient: Client = this.clientsService.CurrentClient.getValue();
        return this.apiService.updatePin(currentClient.id, pinId, data);
    }

    getPinImagesArchive(pinId: number): Observable<any> {
        return this.apiService.getPinImagesArchive(pinId);
    }
}
