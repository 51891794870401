import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take, switchMap, delay, tap, catchError } from 'rxjs/operators';
import Field from '@shared/models/field';
import CropType from '@shared/models/crop-type';
import { LayersService } from '@core/services/layers.service';
import _ from 'lodash';
import { removeDiacritics } from '@shared/utils/utils';
import { PermissionsService } from '@core/services/permissions.service';
import { of, Observable, throwError } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '@environments/environment';

enum FieldProperty {
    NAME,
    CROP_TYPE,
    DETAILS,
}

@Component({
    selector: 'app-field-details',
    templateUrl: './field-details.component.html',
    styleUrls: ['./field-details.component.less'],
})
export class FieldDetailsComponent implements OnInit {
    /**
     * Field related data
     */
    field: Field;
    cropType: CropType;
    cropTypeId: string;
    name: string;
    details: string;
    children: Field[] = [];
    isChild = false;
    parentField: Field;

    /**
     * Style objects
     */
    infoMapStyle = {
        height: '282px',
        width: '100%',
        background: 'white',
        cursor: 'pointer',
    };

    isolatedZoneMapStyle = {
        height: '160px',
        width: '100%',
        background: 'white',
        cursor: 'pointer',
    };

    /**
     * Modals
     */
    isSaving = false;
    FieldProperty = FieldProperty;

    /**
     * Data
     */
    cropTypes: CropType[];

    /**
     * Permissions
     */
    permissions = [];
    canUpdateFieldsProperties = false;

    private isLoggingEnabled = !environment.production;

    constructor(
        private layersService: LayersService,
        private message: NzMessageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private permissionsService: PermissionsService,
        private modalService: NzModalService,
    ) {}

    ngOnInit() {
        // Obtain permissions
        this.permissions = this.permissionsService.Permissions;
        this.canUpdateFieldsProperties = this.permissionsService.canUpdateFieldsProperties();

        this.activatedRoute.params
            .pipe(
                take(1),
                switchMap((params) => {
                    const fieldId = Number.parseInt(params.fieldId);
                    const childId = Number.parseInt(params.childId);

                    this.isChild = !!childId;

                    this.field = _.find(
                        this.layersService.FieldsAndChildren,
                        (field) => field.id === (this.isChild ? childId : fieldId),
                    );

                    console.log('FieldDetails', this.field);

                    if (!this.field) {
                        this.displayMessage('error', 'Terenul accesat nu există pe acest client');
                        throw 'Field not found';
                    }

                    if (this.isChild) {
                        this.parentField = _.find(
                            this.layersService.FieldsAndChildren,
                            (field) => field.id === fieldId,
                        );
                    }

                    this.cropTypes = this.layersService.CropTypes;
                    this.cropType = this.layersService.getCropType(this.field.apia_crop_type);
                    this.cropTypeId = this.cropType.id.toString();
                    this.name = this.field.name;
                    this.details = this.field.details;

                    return this.isChild
                        ? of([])
                        : this.layersService.getChildrenOfField(this.field.id);
                }),
            )
            .subscribe((children: Field[]) => {
                this.children = children;
            });
    }

    displayMessage = (type: string, message: string): any => this.message.create(type, message);

    goBack = (): any => this.router.navigateByUrl('/terenuri');

    /**
     * Compares if a string contains another one, ignoring diacritics
     */
    compare = (value, option) => removeDiacritics(option.nzLabel).includes(removeDiacritics(value));

    onOkEditingModal = (property: FieldProperty): Observable<Field> => {
        this.isSaving = true;
        let data: {};

        switch (property) {
            case FieldProperty.CROP_TYPE:
                const apia_crop_type: number = Number.parseInt(this.cropTypeId);
                data = { apia_crop_type };
                break;

            case FieldProperty.NAME:
                data = { name: this.name };
                break;

            case FieldProperty.DETAILS:
                data = { details: this.details };
                break;

            default:
                console.error('[FieldDetails] Unknown editing property', property);
        }

        return this.layersService
            .updateField(this.field.id, data)
            .pipe(catchError(this.errorHandler), delay(100));
    };

    openEditingModal = (property: FieldProperty, content: TemplateRef<{}>): void => {
        if (this.canUpdateFieldsProperties) {
            switch (property) {
                case FieldProperty.CROP_TYPE:
                    this.modalService.create({
                        nzTitle: 'Editează cultura',
                        nzContent: content,
                        nzOkText: 'Salvează',
                        nzCancelText: 'Anulează',
                        nzMaskClosable: false,
                        nzClosable: true,
                        nzOnOk: () =>
                            this.onOkEditingModal(FieldProperty.CROP_TYPE)
                                .pipe(
                                    tap(
                                        (field: Field) => {
                                            this.onSuccessfulUpdate();
                                            this.cropType = this.layersService.getCropType(
                                                field.apia_crop_type,
                                            );
                                            this.field.apia_crop_type = field.apia_crop_type;
                                        },
                                        (error) => {
                                            this.onUpdateError(error);
                                        },
                                    ),
                                )
                                .toPromise(),
                    });
                    break;

                case FieldProperty.NAME:
                    this.modalService.create({
                        nzTitle: 'Editează denumirea',
                        nzContent: content,
                        nzOkText: 'Salvează',
                        nzCancelText: 'Anulează',
                        nzMaskClosable: false,
                        nzClosable: true,
                        nzOnOk: () =>
                            this.onOkEditingModal(FieldProperty.NAME)
                                .pipe(
                                    tap(
                                        (field: Field) => {
                                            this.onSuccessfulUpdate();
                                            this.field.name = field.name;
                                        },
                                        (error) => {
                                            this.onUpdateError(error);
                                        },
                                    ),
                                )
                                .toPromise(),
                    });
                    break;

                case FieldProperty.DETAILS:
                    this.modalService.create({
                        nzTitle: 'Editează detaliile',
                        nzContent: content,
                        nzOkText: 'Salvează',
                        nzCancelText: 'Anulează',
                        nzMaskClosable: false,
                        nzClosable: true,
                        nzOnOk: () =>
                            this.onOkEditingModal(FieldProperty.DETAILS)
                                .pipe(
                                    tap(
                                        (field: Field) => {
                                            this.onSuccessfulUpdate();
                                            this.field.details = field.details;
                                        },
                                        (error) => {
                                            this.onUpdateError(error);
                                        },
                                    ),
                                )
                                .toPromise(),
                    });
                    break;

                default:
                    console.error('[FieldDetails] Unknown editing property', property);
            }
        } else {
            this.displayMessage('warning', 'Nu ai permisiunea de a edita terenul');
        }
    };

    private onSuccessfulUpdate = (): void => {
        this.displayMessage('success', 'Terenul a fost modificat cu succes');
        this.isSaving = false;
    };

    private onUpdateError = (error: any): void => {
        console.error(error);
        this.displayMessage('error', 'A apărut o eroare la salvare');
        this.isSaving = false;
    };

    private errorHandler = (errorResponse: Response): Observable<never> => {
        console.error('[ClientsList] Error Handler');
        console.error(errorResponse);
        this.displayMessage('error', 'A apărut o eroare');

        return throwError(errorResponse || 'API Error');
    };
}
